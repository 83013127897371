import React from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  async function goToHome() {
    navigate("/")
  }

  async function goToCreateAd() {
    navigate("/create-ad");
  }

  async function goToProfile() {
    navigate("/profile");
  }

  async function logout() {
    localStorage.removeItem("token");
    navigate("/login");
  }

  async function goToMyTransactions(){
    navigate("/my-transactions")
  }

  return (
    <>
      {/* Navbar for Create Ad, Edit Profile, Logout using tailwind*/}
      <div className="flex justify-between bg-gray-800 p-4">
        <div className="text-white cursor-pointer" onClick={goToHome}>
          Home
        </div>
        <div className="text-white cursor-pointer" onClick={goToCreateAd}>
          Create Ad
        </div>
        <div className="text-white cursor-pointer" onClick={goToProfile}>
          Profile
        </div>
        <div className="text-white cursor-pointer" onClick={goToMyTransactions}>
          My Transactions
        </div>
        <div className="text-white cursor-pointer" onClick={logout}>
          Logout
        </div>
      </div>
    </>
  );
};

export default Navbar;
