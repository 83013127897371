import React from "react";
import { Buffer } from "buffer";
import process from "process";
import "./App.css";
import { Routes, Router, Route, BrowserRouter } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import ForgotPassword from "./pages/ForgotPassword";
import CreateAd from "./pages/CreateAd";
import EditProfile from "./pages/EditProfile";
import Ads from "./pages/Ads";
import Admin from "pages/Admin";
import Profile from "./pages/Profile"
import MyBookings from "pages/MyBookings";
import MyAds from "pages/MyAds";
import MyTransactions from "pages/MyTransactions"
import AdminLogin from "pages/AdminLogin";
import Requests from "pages/Requests";
import AdminUserDetails from "pages/AdminUserDetails"
// import ValidateOtp from "pages/ValidateOtp";
import AdminVerifyOtp from "pages/AdminVerifyOtp";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/create-ad" element={<CreateAd />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/ads/:id" element={<Ads />} />
        <Route path="/admin-dashboard" element={<Admin />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/my-bookings" element={<MyBookings />} />
        <Route path="/my-ads" element={<MyAds />} />
        <Route path="/my-transactions" element={<MyTransactions />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/my-ads/requests/:id" element={<Requests />} />
        <Route path="/admin/user-details/:email" element={<AdminUserDetails />} />
        <Route path="/admin/validate-otp" element={<AdminVerifyOtp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
