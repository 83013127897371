import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import axios from "axios";

const Home = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [ads, setAds] = React.useState([]);
  const [filterOptions, setFilterOptions] = React.useState({
    price: 0,
    room_cooling: true,
    furnished: "not",
    total_rooms: 1,
    hot_cold_water: true,
    food: true,
    taxi_service: true,
    doctor_on_call: true,
  });
  React.useEffect(() => {
    checkToken();
    fetchAds();
  }, []);
  if (!token) {
    navigate("/")
    // return <div>Not logged in</div>;
  }

  async function checkToken() {
    // check if token is valid
    // if not redirect to login page
    // else continue
    const data = await axios.post("https://homestaykashmir.com:6969/api/verify-token", {
      token
    });
    const response = await data.data;
    if (response.status !== 200) {
      navigate("/login");
    }
  }

  async function fetchAds() {
    const data = await axios.post("https://homestaykashmir.com:6969/api/get-ads", {
      token
    });
    const response = await data.data
    if (response.status !== 200) {
      return;
    }
    setAds(response.ads);
    // console.log(response);
  }

  // fetch all ads from the server
  const handleFilter = async () => {
    // console.log(filterOptions);
    // send request to server to filter ads
    const data = await fetch("https://homestaykashmir.com:6969/api/filter-ads", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(filterOptions),
    });
    const response = await data.json();
    // console.log(response);
    setAds(response.ads);
  };
  return (
    <>
      <Navbar />
      {/* Filter options by price, room cooling, furnished , total rooms, hot_cold_water, food, taxi_service, doctor_on_call,  */}
      <div className="flex justify-center">
        <div className="flex  p-4">
          <h1 className="text-2xl">Filter Options</h1>
          <div className="flex flex-col p-4">
            <label>Price</label>
            <input
              // value={filterOptions.price}
              onChange={(e) =>
                setFilterOptions({
                  ...filterOptions,
                  price: Number(e.target.value),
                })
              }
              type="number"
              placeholder="Price"
              className="border p-2"
            ></input>
          </div>
          <div className="flex flex-col p-4">
            <label>Room Cooling</label>
            <select
              className="border p-2"
              defaultValue={"1"}
              // value={true ? "1" : "0"}
              onChange={(e) =>
                setFilterOptions({
                  ...filterOptions,
                  room_cooling: e.target.value === "1" ? true : false,
                })
              }
            >
              <option value="1">Available</option>
              <option value="0">Not Available</option>
            </select>
          </div>
          <div className="flex flex-col p-4">
            <label>Furnished</label>
            <select defaultValue={"not"} className="border p-2">
              <option value="not">Not</option>
              <option value="semi">Semi</option>
              <option value="full">Full</option>
            </select>
          </div>
          <div className="flex flex-col p-4">
            <label>Total Rooms</label>
            <input
              value={filterOptions.total_rooms}
              type="number"
              placeholder="Total Rooms"
              className="border p-2"
            ></input>
          </div>
          <div className="flex flex-col p-4">
            <label>Hot and Cold Water</label>
            <select
              className="border p-2"
              value={true ? "1" : "0"}
              defaultValue={"1"}
              onChange={(e) =>
                setFilterOptions({
                  ...filterOptions,
                  hot_cold_water: e.target.value === "1" ? true : false,
                })
              }
            >
              <option value="1">Available</option>
              <option value="0">Not Available</option>
            </select>
          </div>
          <div className="flex flex-col p-4">
            <label>Food</label>
            <select
              className="border p-2"
              value={true ? "1" : "0"}
              defaultValue={"1"}
              onChange={(e) =>
                setFilterOptions({
                  ...filterOptions,
                  food: e.target.value === "1" ? true : false,
                })
              }
            >
              <option value="1">Available</option>
              <option value="0">Not Available</option>
            </select>
          </div>
          <div className="flex flex-col p-4">
            <label>Taxi Service</label>
            <select
              className="border p-2"
              value={true ? "1" : "0"}
              defaultValue={"1"}
              onChange={(e) =>
                setFilterOptions({
                  ...filterOptions,
                  taxi_service: e.target.value === "1" ? true : false,
                })
              }
            >
              <option value="1">Available</option>
              <option value="0">Not Available</option>
            </select>
          </div>
          <div className="flex flex-col p-4">
            <label>Doctor on Call</label>
            <select className="border p-2">
              <option value="1">Available</option>
              <option value="0">Not</option>
            </select>
          </div>
          <button className="bg-black text-white p-2" onClick={handleFilter}>
            Filter
          </button>
        </div>
      </div>

      <div>
        <h1>ADS</h1>
        <>
          {ads.length <= 0 ? (
            <>No Ads</>
          ) : (
            ads.map((ad: any) => {
              return (
                <div
                  key={ad.id}
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <h1>Title : {ad.title}</h1>

                  <p>Description : {ad.description}</p>
                  <p>City : {ad.house_city}</p>
                  <p>State : {ad.house_state}</p>
                  <p>Price per room : {ad.price_per_room}</p>
                  <p>Total rooms : {ad.total_rooms}</p>
                  {/* room cooling available if 1 else not available */}
                  <p>
                    Room cooling :{" "}
                    {ad.room_cooling === 1 ? "Available" : "Not Available"}
                  </p>
                  {/* furnished if 1 else not furnished */}
                  <p>Furnished : {ad.furnished === 1 ? "Yes" : "No"}</p>
                  {/* hot and cold water available if 1 else not available */}
                  <p>
                    Hot and cold water :{" "}
                    {ad.hot_cold_water === 1 ? "Available" : "Not Available"}
                  </p>
                  {/* food available if 1 else not available */}
                  <p>Food : {ad.food === 1 ? "Available" : "Not Available"}</p>
                  {/* taxi service available if 1 else not available */}
                  <p>
                    Taxi service :{" "}
                    {ad.taxi_service === 1 ? "Available" : "Not Available"}
                  </p>
                  {/* doctor on call available if 1 else not available */}
                  <p>
                    Doctor on call :{" "}
                    {ad.doctor_on_call === 1 ? "Available" : "Not Available"}
                  </p>
                  {/* ad is active if 1 else not active */}
                  <p>Active : {ad.is_active === 1 ? "Yes" : "No"}</p>
                  <a href={`/ads/${ad.id}`} target="_blank">
                    {" "}
                    <button className="bg-black text-white p-2">
                      View Details{" "}
                    </button>
                  </a>
                </div>
              );
            })
          )}
        </>
      </div>
    </>
  );
};

export default Home;
