import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "./Navbar";
import { Dropbox } from "dropbox";

const CreateAd = () => {
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [house_city, setHouseCity] = React.useState("");
  const [house_state, setHouseState] = React.useState("");
  const [longitude, setLongitude] = React.useState("");
  const [latitude, setLatitude] = React.useState("");
  const [price_per_room, setPricePerRoom] = React.useState<any>();
  const [total_rooms, setTotalRooms] = React.useState<any>();
  const [room_cooling, setRoomCooling] = React.useState(false);
  const [furnished, setFurnished] = React.useState("semi");
  const [hot_cold_water, setHotColdWater] = React.useState(false);
  const [food, setFood] = React.useState(false);
  const [taxi_service, setTaxiService] = React.useState(false);
  const [doctor_on_call, setDoctorOnCall] = React.useState(false);
  const [is_active, setIsActive] = React.useState(false);
  // const [file, setFile] = React.useState([] as any);

  const [userId, setUserId] = React.useState("");
  const [files, setFiles] = React.useState<File[]>([]);
  const [verified, setVerified] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleFileChange = (e: any) => {
    // const selectedFiles: any = Array.from(e.target.files);
    setFiles(e.target.files);
  };
  // ask for location permission and get current location using ipgeolocation.io api
  const getLongLat = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const latitude = position.coords.latitude.toString();
        const longitude = position.coords.longitude.toString();;
        setLatitude(latitude);
        setLongitude(longitude);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };


  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  async function checkToken() {
    if (!token) {
      // redirect to login page
      navigate("/login");
    } else {
      // check if token is valid
      // if not redirect to login page
      // else continue
      const data = await axios.post("https://homestaykashmir.com:6969/api/verify-token", {
        token,
      });
      if (data.data.status !== 200) {
        // navigate("/login");
        setVerified(false)
      }
      else if (data.data.status === 200) {
        setVerified(true)
      }
      setUserId(data.data.id);
    }
  }
  function generateRandomString(length: number) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async function CheckUserVerified() {
    setLoading(true)
    const data = await axios.post("https://homestaykashmir.com:6969/api/check-if-user-verified", {
      token,
    });
    if (data.data.status !== 200) {
      // navigate("/verify");
      setVerified(false)
      setLoading(false)
    }

    if (data.data.status === 200) {
      setVerified(true)
      setLoading(false)
    }
  }

  React.useEffect(() => {
    checkToken();
    CheckUserVerified();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = await axios.post("https://homestaykashmir.com:6969/api/create-ad", {
      title,
      description,
      house_city,
      house_state,
      longitude,
      latitude,
      price_per_room,
      total_rooms,
      room_cooling,
      furnished,
      hot_cold_water,
      food,
      taxi_service,
      doctor_on_call,
      is_active,
      token,
    });

    if (data.data.status === 200) {
      alert("Ad created successfully");

      const formData = new FormData();
      formData.append("token", token as any)
      formData.append("ad_id", data.data.ad_id)
      Array.from(files).forEach(file => {
        formData.append("images", file)
      })

      try {
        const response = await axios.post("https://homestaykashmir.com:6969/api/upload", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log("Files uploaded")
      } catch (error) {
        console.log(error)
      }
      navigate("/");

      // const uploadToDropbox = async () => {
      //   // Dropbox access token (replace with your actual token)
      //   const accessToken =
      //     "sl.B_BOkunzxRu8pNiKnejg4ts1yjJ_7TB5Ud2W6bOUAUVI6GDHwrhzlE5eP6lOtJwqYe3mG-45An71sUuWjo5OTn1CDb-LYPSDRa-RoNgd6n_EYdQF65E5HpZofU6di-pfHITIQtt0LeRssUGDAjQhht4";

      //   // Initialize Dropbox client
      //   const dbx = new Dropbox({ accessToken });

      //   for (let i = 0; i < files.length; i++) {
      //     const file = files[i];

      //     try {
      //       // Read file content
      //       const fileContent = await file.arrayBuffer();

      //       // Upload to Dropbox
      //       const uploadResult = await dbx.filesUpload({
      //         path: `/${token}/${data.data.ad_id}/${file.name}`,
      //         contents: fileContent,
      //       });

      //       // console.log(`File uploaded: ${uploadResult.result.name}`);
      //     } catch (error) {
      //       console.error(`Error uploading ${file.name}:`, error);
      //     }
      //   }
      // };
      // uploadToDropbox();
    } else {
      alert(data.data.message);
    }
  };

  return (
    <>
      <Navbar />
      {
        !verified ? <div> wait for your account to get verified before posting ad</div> :
          <>
            {loading ? <div>Loading ...</div> :
              <>
                <div>

                  <form className="flex flex-col" onSubmit={handleSubmit}>
                    {/* take all inputs, make sure images to be taken as image inputs add tailwind classes to beautify */}
                    <label htmlFor="title">Title</label>
                    <input
                      required
                      type="text"
                      placeholder="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      className="border border-gray-300 rounded-md p-2"
                    />
                    <label htmlFor="description">Description</label>
                    <input
                      required
                      type="text"
                      placeholder="Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="border border-gray-300 rounded-md p-2"
                    />
                    <label htmlFor="images-url">Images URL</label>
                    {/* take image input  */}
                    <input
                      accept="image/*"
                      required
                      type="file"
                      onChange={handleFileChange}
                      multiple
                      className="border border-gray-300 rounded-md p-2"
                    />


                    <label htmlFor="house-city">House City</label>
                    <input
                      required
                      type="text"
                      placeholder="House City"
                      value={house_city}
                      onChange={(e) => setHouseCity(e.target.value)}
                      className="border border-gray-300 rounded-md p-2"
                    />
                    <label htmlFor="house-state">House State</label>
                    <input
                      required
                      type="text"
                      placeholder="House State"
                      value={house_state}
                      onChange={(e) => setHouseState(e.target.value)}
                      className="border border-gray-300 rounded-md p-2"
                    />
                    {/* input for longitude and lattitude */}
                    <label htmlFor="longitude">Longitude</label>
                    <input
                      required
                      type="text"
                      placeholder="Longitude"
                      value={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                      className="border border-gray-300 rounded-md p-2"
                    />
                    <label htmlFor="latitude">Latitude</label>
                    <input
                      required
                      type="text"
                      placeholder="Latitude"
                      value={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                      className="border border-gray-300 rounded-md p-2"
                    />
                    <label htmlFor="price-per-room">Price Per Room</label>
                    <input
                      required
                      type="number"
                      placeholder="Price Per Room"
                      value={price_per_room}
                      onChange={(e) => setPricePerRoom(e.target.value)}
                      className="border border-gray-300 rounded-md p-2"
                    />
                    <label htmlFor="total-rooms">Total Rooms</label>
                    <input
                      required
                      type="number"
                      placeholder="Total Rooms"
                      value={total_rooms}
                      onChange={(e) => setTotalRooms(e.target.value)}
                      className="border border-gray-300 rounded-md p-2"
                    />

                    <label htmlFor="room-cooling">Room Cooling</label>
                    <input
                      type="checkbox"
                      onChange={(e) => setRoomCooling(e.target.checked)}
                      className="border border-gray-300 rounded-md p-2"
                    />
                    <label htmlFor="furnished">Furnished</label>
                    {/* dropdown for furnished "semi", "full" and not */}
                    <select
                      required
                      onChange={(e) => setFurnished(e.target.value)}
                      className="border border-gray-300 rounded-md p-2"
                      defaultValue={"semi"}
                    >
                      <option value="semi">Semi</option>
                      <option value="full">Full</option>
                      <option value="not">Not</option>
                    </select>
                    <label htmlFor="hot-cold-water">Hot Cold Water</label>
                    <input
                      type="checkbox"
                      onChange={(e) => setHotColdWater(e.target.checked)}
                      className="border border-gray-300 rounded-md p-2"
                    />
                    <label htmlFor="food">Food</label>
                    <input
                      type="checkbox"
                      onChange={(e) => setFood(e.target.checked)}
                      className="border border-gray-300 rounded-md p-2"
                    />
                    <label htmlFor="taxi-service">Taxi Service</label>
                    <input
                      type="checkbox"
                      onChange={(e) => setTaxiService(e.target.checked)}
                      className="border border-gray-300 rounded-md p-2"
                    />
                    <label htmlFor="doctor-on-call">Doctor On Call</label>
                    <input
                      type="checkbox"
                      onChange={(e) => setDoctorOnCall(e.target.checked)}
                      className="border border-gray-300 rounded-md p-2"
                    />
                    <label htmlFor="is-active">Is Active</label>
                    <input
                      type="checkbox"
                      onChange={(e) => setIsActive(e.target.checked)}
                      className="border border-gray-300 rounded-md p-2 mb-5"
                    />
                    <button
                      type="submit"
                      className="bg-blue-500 text-white p-2 rounded-md mb-5"
                    >
                      Create Ad
                    </button>
                  </form>
                </div>
              </>
            }
          </>
      }

    </>
  );
};

export default CreateAd;
